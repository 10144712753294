.emoji-mart {
  position: absolute;
  bottom: 20px;
  left: -80px;
}

.emoji-mart-preview {
  display: none;
}

// dropdown
.dropdown-toggle {
  &:after {
    display: none;
  }
}

.modal-backdrop {
  z-index: 1002 !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: #fff !important;
  color: #495057 !important;
}

// ----- Advance Ui --------//
// Rating
.stars {
  font-size: 30px;
  color: var(--bs-gray-600);
}

.star {
  position: relative;
  display: inline-block;
}

.star_fill {
  color: $gray-500;
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--bs-gray-600);
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

.halfHeart {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: red;
}

// Calendar
.flatpickr-calendar.open {
  z-index: 1061;
}

// Swich Btn
.switch.switch-small.checked > .switch-pane > span {
  color: #fff;
}

.switch.switch-small > .switch-pane > span {
  font-size: 14px;
}

// Ck Editer
.ck-editor__editable {
  min-height: 245px !important;
}

.custom-accordion {
  .accordion-list {
    padding: 0px;
    background-color: transparent;
  }
}

.topnav {
  .navbar-nav {
    .nav-link {
      font-size: 15px;
      font-weight: 300;
      color: $tso-life-secondary-color;

      &:focus,
      &:hover {
        color: $menu-item-active-color;
        background-color: transparent;
      }
    }

    .nav-item {
      &:hover {
        background-color: $gray-300;
      }

      &:first-of-type .nav-link {
        padding-left: initial;
      }
    }
  }
}

@media (min-width: 992px) {
  .topnav {
    .navbar-nav {
      .nav-item {
        &:first-of-type .nav-link {
          padding-left: 1.3rem;
        }

        &.dropdown {
          > .dropdown-menu {
            min-width: 100%;
            padding: 0;
          }
        }
      }
    }
  }
}

table.table {
  thead {
    tr {
      th {
        vertical-align: middle;
        line-height: normal;

        &[sortable] {
          cursor: pointer;
          position: relative;
          padding: 0 10px 10px 14px;

          &:before,
          &:after {
            font-family: 'Font Awesome 5 Free';
            position: absolute;
            width: 10px;
            height: 10px;
            left: 0;
            color: $gray-500;
          }

          &:before {
            //Up
            content: '\f106';
            top: calc(50% - 16px);
            bottom: auto;
          }

          &:after {
            //Down
            content: '\f107';
            top: auto;
            bottom: calc(50% - 1px);
          }

          &.asc {
            &:after {
              color: $tso-life-primary-color;
            }
          }

          &.desc {
            &:before {
              color: $tso-life-primary-color;
            }
          }
        }
      }
    }
  }
}

#preloader {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-control {
  &[type='color'] {
    height: 37px;
  }
}

// ngx-dropzone {
//   border: 1px dashed #717386 !important;
// }

.btn-soft-primary {
  &.active {
    color: $white;
    background-color: $tso-life-primary-color;
    border-color: $tso-life-primary-color;
  }
}

.card {
  &.card-theme {
    .card-header {
      background: none;
      overflow: hidden;

      .card-title {
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .carousel {
      .carousel-inner {
        height: 150px;

        .carousel-item {
          height: 150px;

          app-images {
            position: relative;
            overflow: hidden;
            height: 150px;
            line-height: 150px;
            text-align: center;

            img {
              position: relative;
              margin: -50% auto; /* virtualy height needed turn don to zero */
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.newsletter {
  .newsletter-ctn {
    margin: 0;
    padding: 0;

    .page {
      background-color: #fff;
      padding: 20px 35px;

      &:first-child {
        border-right: 1px solid #dcdcdc;
        padding-left: 15px;
      }

      &:last-child {
        border-left: 1px solid #dcdcdc;
        padding-right: 15px;
      }

      .title {
        margin-bottom: 10px;

        p {
          font-size: 18px;
        }
      }

      .text {
        margin-bottom: 10px;
      }

      p {
        font-size: 12px;
        text-align: justify;
        margin-bottom: 0 !important;
      }

      .color-box {
        color: #fff;
        font-size: 12px;
        padding: 10px 20px;

        ul {
          padding: 0 15px;
        }

        .list-ckeditor {
          ul {
            padding: 0 15px;

            li {
              &::marker {
                color: red;
              }
            }
          }
        }

        p,
        li {
          color: #FFFFFF !important;
        }
      }
    }

    .el-upload-list--picture-card {
      .el-upload-list__item-actions {
        width: 100%;
        height: 100%;
        right: 0 !important;
        left: revert !important;
        border-bottom-left-radius: 8px;
      }

      ngx-dropzone,
      .items-upload-wrap {
        width: 100% !important;
        text-align: center;
        background-position: 50%;
        border: none;
        background-size: 120%;
        background-position-y: center;
        margin: 0 8px 15px 0;
        border-radius: 6px;
        border: none !important;
        position: relative;
        cursor: pointer;
        display: flex;

        .item-placeholder {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 1;
          border-radius: 6px;
        }

        .ngx-dropzone-image-preview,
        .item-image-preview {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          opacity: 1;
          border-radius: 6px;
        }

        .item-remove {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 6px;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          cursor: pointer;
          opacity: 0;
          transition: opacity 0.3s;

          svg {
            line {
              stroke-width: 2px;
              stroke: #fff;
            }
          }
        }

        &:hover {
          .item-remove {
            opacity: 1;
          }
        }

        ngx-dropzone-image-preview {
          position: absolute !important;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 1 !important;
          margin: 0 !important;
          height: auto !important;
          min-height: unset !important;
          min-width: unset !important;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 6px;
            opacity: 1;
          }
        }

        ngx-dropzone-label,
        .item-btn {
          margin: auto auto 15px;
          color: #fff;
          background-color: #07b59a;
          border: none;
          border-radius: 10px;
          padding: 4px;
          font-size: 10px;
          min-width: 65px;
          line-height: 1.42857143;
          text-align: center;
          text-transform: uppercase;
          position: relative;

          &:hover {
            background-color: #058f7b;
          }
        }
      }
    }
  }

  .newsletter-headless {
    .headless-size {
      width: 17in;
      height: 11in;
      margin: auto;
    }
  }
}

.cke_editable {
  border: 1px dotted #dfdfdf;
}

.page-headless {
  .cke_editable {
    border: none !important;
  }
}

.color-box-white-text-right p {
  color: #FFFFFF !important;
  text-align: right;
}

.color-box-white-text-left p {
  color: #FFFFFF !important;
  text-align: left;
}

.fixed-visible {
  width: 1465px;
  height: 878px;
  margin: auto;
}

.headless {
  width: 17in;
  height: 11in;

  .cke_editable {
    border: none !important;
  }

  .item-remove {
    display: none !important;
  }

  .page p {
    font-size: 16px !important;
  }

  .title p {
    font-size: 24px !important;
  }
}

.newsletter .newsletter-ctn .page .color-box .list-icons li,
.newsletter .newsletter-ctn .page .color-box .list li {
  padding: 0;
}

.newsletter
.newsletter-ctn
.el-upload-height-140
.el-upload-list--picture-card
.items-upload-wrap {
  height: 140px;
}

.newsletter
.newsletter-ctn
.el-upload-height-135
.el-upload-list--picture-card
.items-upload-wrap {
  height: 135px;
}

.newsletter
.newsletter-ctn
.el-upload-height-235
.el-upload-list--picture-card
.items-upload-wrap {
  height: 235px;
}

.newsletter
.newsletter-ctn
.el-upload-height-110
.el-upload-list--picture-card
.items-upload-wrap {
  height: 110px;
}

.newsletter
.newsletter-ctn
.el-upload-height-190
.el-upload-list--picture-card
.items-upload-wrap {
  height: 190px;
}

.newsletter
.newsletter-ctn
.el-upload-height-330
.el-upload-list--picture-card
.items-upload-wrap {
  height: 330px;
}

.newsletter
.newsletter-ctn
.el-upload-height-175
.el-upload-list--picture-card
.items-upload-wrap {
  height: 175px;
}

.newsletter
.newsletter-ctn
.el-upload-height-150
.el-upload-list--picture-card
.items-upload-wrap {
  height: 150px;
}

.newsletter .mt-10 {
  margin-top: 10px;
}

.newsletter .newsletter-ctn .background-default img {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.newsletter .newsletter-ctn .background-default .btn {
  position: relative;
  bottom: -68%;
}

.newsletter .upload-dialog input[type='file'] {
  display: none;
}

.template-option {
  max-height: 100px;
}

.contentTitle1 {
  text-align: left;
  margin: 0;
}

.custom-counter {
  margin-left: 10px;
}

.pdf-printable {
  padding: 30px 20px;
}

.tso-nav-pills {
  height: 100%;
  border: solid 1px $gray-300;

  > .nav-item {
    > .nav-link {
      border-radius: 0;

      &.active {
        color: $tso-life-primary-color;
        background-color: $tso-life-primary-color-light;
      }
    }
  }
}

.flex-even {
  flex: 1;
}

.profile-user-wid {
  app-images {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;

    img {
      object-fit: cover;
      max-width: 100%;
      aspect-ratio: 1 / 1;
    }
  }
}

.form-control {
  &.ng-select {
    border: none;
    padding: 0;
    margin: 0;

    > .ng-select-container {
      height: auto;
      min-height: 41.52px;

      > .ng-value-container {
        > div {
          > input {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
          }
        }
      }
    }

    > .ng-dropdown-panel {
      z-index: 10;

      &.ng-select-bottom {
        margin-top: -2px;
      }
    }

    &.is-invalid {
      > .ng-select-container {
        border-color: #f46a6a;
      }
    }
  }
}

app-gallery-input {
  .d-flex {
    .flex-grow-1 {
      ngx-dropzone {
        height: auto;
      }
    }
  }
}

app-user-media {
  ngx-dropzone {
    height: auto;
  }
}

ngb-modal-window {
  .modal-dialog {
    .modal-content {
      border: none;
    }
  }
}

app-images {
  > img:not(.no-ratio) {
    object-fit: cover;
    max-width: 100%;
    aspect-ratio: 1/1;
  }
}

.h-150 {
  height: 150px;
}

.h-200 {
  height: 200px;
}

body {
  .page-content {
    min-height: 100vh;
  }
}

body[data-layout='horizontal'] {
  &[data-application='profile'] {
    .page-content {
      margin-top: $header-height;
      padding: #{$grid-gutter-width} calc(#{$grid-gutter-width} * 0.5) $footer-height calc(#{$grid-gutter-width} * 0.5);
    }
  }
}

#page-header-user-dropdown {
  overflow: hidden;
  max-width: 200px;

  .username {
    text-overflow: ellipsis;
    max-width: 164px;
    overflow: hidden;
  }
}

.mini-stats-wid-forms {
  > a.active {
    .mini-stats-wid {
      background-color: $tso-life-primary-color;
      color: $white;

      .text-secondary {
        color: $white !important;
      }

      .avatar-title {
        background-color: $white;
        color: $tso-life-primary-color;
      }
    }
  }

  .mini-stats-wid {
    &.forms {
      &:hover {
        background-color: $tso-life-primary-color;
        color: $white;

        .text-secondary {
          color: $white !important;
        }

        .avatar-title {
          background-color: $white;
          color: $tso-life-primary-color;
        }
      }
    }
  }
}

ngb-datepicker.dropdown-menu {
  .ngb-dp-navigation-select {
    .form-select {
      margin: 0 6px;
    }
  }

  .ngb-dp-day {
    .custom-day {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      &:hover:not(.faded),
      &.range {
        background-color: #07b59a;
        color: #fff;
      }

      &.faded {
        background-color: #e6f8f5;
        color: #495057;
      }
    }
  }
}

.pagination {
  ngb-pagination {
    .pagination {
      .page-item {
        .page-link {
          width: 40px;
          height: 40px;
          line-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

ngx-dropzone {
  position: relative;

  .ngx-dropzone-image-preview {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    opacity: 1 !important;
    margin: 0 !important;
    height: auto !important;
    min-height: unset !important;
    min-width: unset !important;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      opacity: 1;
    }
  }

  ngx-dropzone-label,
  .item-btn {
    margin: auto auto 15px;
    color: #fff;
    background-color: #07b59a;
    border: none;
    border-radius: 10px;
    padding: 4px 6px;
    font-size: 10px;
    min-width: 65px;
    line-height: 1.42857143;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    z-index: 2 !important;

    &:hover {
      background-color: #058f7b;
    }
  }
}

.tso-doughnut {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.mxw-160 {
  max-width: 160px;
}

.table-responsive {
  table {
    &.table {
      > tbody {
        > tr {
          > td {
          }
        }
      }
    }
  }
}

.input-group {
  &.no-border {
    .input-group-text {
      background: transparent;
      border-right: transparent 0;
      padding-right: 0;
    }

    .form-control {
      border-left: transparent 0;
    }
  }
}

@each $key, $value in $colors {
  .bg-#{$key} {
    background-color: $value;
  }
  .txt-#{$key} {
    color: $value;
  }
  .border-#{$key} {
    border-color: $value !important;
  }
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols
ul.steps-indicator
li.done
.step-indicator {
  border: 2px solid $tso-life-primary-color;
  color: $tso-life-primary-color;
}

.toast-fixed {
  position: fixed;
  top: 20px;
  right: 20px;
  margin-bottom: 20px;
}

.card-radio {
  background-color: var(--#{$prefix}custom-white);
  border: 2px solid var(--#{$prefix}border-color);
  border-radius: $border-radius;
  padding: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.card-radio-label {
  display: block;
}

.card-radio-input {
  display: none;

  &:checked + .card-radio {
    border-color: $primary !important;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}

.img-canvas {
  text-align: center; /* ensures the image is always in the h-middle */
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: relative; /* allows repositioning */
    left: 100%; /* move the whole width of the image to the right */
    margin-left: -200%; /* magic! */
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    height: auto;
    object-fit: cover;
    object-position: center; /* Center the image within the element */
  }
}

.text-shadow-black {
  /* offset-x | offset-y | blur-radius | color */
  text-shadow: 1px 1px 4px black;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }
    .border#{$infix}-right {
      border-right: $border-width solid $border-color !important;
    }
    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }
    .border#{$infix}-left {
      border-left: $border-width solid $border-color !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }
  }
}

.tippy-box[data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity;
}

.tippy-box[data-placement^='top'] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^='top'] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}

.tippy-box[data-placement^='bottom'] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}

.tippy-box[data-placement^='left'] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^='left'] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}

.tippy-box[data-placement^='right'] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^='right'] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}

.tippy-box[data-inertia][data-state='visible'] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: '';
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-content {
  position: relative;
  padding: 0;
  z-index: 1;

  .card {
    margin-bottom: 5px;
    padding: 0;
  }
}

.tippy-box[data-theme~='light'] {
  color: #26323d;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15),
  0 4px 80px -8px rgba(36, 40, 47, 0.25),
  0 4px 4px -2px rgba(91, 94, 105, 0.15);
  background-color: #fff;
}

.tippy-box[data-theme~='light'][data-placement^='top'] > .tippy-arrow:before {
  border-top-color: #fff;
}

.tippy-box[data-theme~='light'][data-placement^='bottom']
> .tippy-arrow:before {
  border-bottom-color: #fff;
}

.tippy-box[data-theme~='light'][data-placement^='left'] > .tippy-arrow:before {
  border-left-color: #fff;
}

.tippy-box[data-theme~='light'][data-placement^='right'] > .tippy-arrow:before {
  border-right-color: #fff;
}

.tippy-box[data-theme~='light'] > .tippy-backdrop {
  background-color: #fff;
}

.tippy-box[data-theme~='light'] > .tippy-svg-arrow {
  fill: #fff;
}

.tippy-box .fa {
  font-size: 20px;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  text-align: center;
  line-height: 37px;
}

.tippy-box h5 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}

#editIcon,
#closeIcon,
#addAttendance,
#deleteIcon {
  cursor: pointer;
  margin-right: 3px;
}

.manage-prospect-modal {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  top: 32%;
}

// Modal on Personalized Video Prospect
.custom-modal-prospect {
  max-height: 95vh !important;
}


// CKEDitor Height
.cover,
.option-1,
.option-2 {
  .cke_editable {
    border: 1px dotted #535353;
    margin-bottom: 5px !important;
  }

  p {
    margin: 0;

  }
}

.h-30 {
  .cke_textarea_inline {
    height: 30px;
    overflow: scroll;
  }
}

.h-60 {
  .cke_textarea_inline {
    height: 60px;
    overflow: scroll;
  }
}

.h-185 {
  .cke_textarea_inline {
    height: 185px;
    overflow: scroll;
  }
}

.h-190 {
  .cke_textarea_inline {
    height: 190px;
    overflow: scroll;
  }
}


.h-350 {
  .cke_textarea_inline {
    height: 350px;
    overflow: scroll;
  }
}


.h-460 {
  .cke_textarea_inline {
    height: 460px;
    overflow: scroll;
  }
}


.h-545 {
  .cke_textarea_inline {
    height: 545px;
    overflow: scroll;
  }
}

.h-600 {
  .cke_textarea_inline {
    height: 600px;
    overflow: scroll;
  }
}

.h-635 {
  .cke_textarea_inline {
    height: 635px;
    overflow: scroll;
  }
}

.h-660 {
  .cke_textarea_inline {
    height: 660px;
    overflow: scroll;
  }
}

.h-700 {
  .cke_textarea_inline {
    height: 700px;
    overflow: scroll;
  }
}

.h-905 {
  .cke_textarea_inline {
    height: 905px;
    overflow: scroll;
  }
}
